import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { EndingShippedSkeleton } from "./skeleton";

const EndingCards = ({ endingData, isEndingDataLoading }) => {
  if (isEndingDataLoading) {
    return <EndingShippedSkeleton />;
  }

  return (
    <Card className="endingStyle ">
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>Ending Soon</Col>
            </Row>
            <Row>
              <Col style={{ paddingTop: "18px" }}>
                <p style={{ textAlign: "center" }}>Today </p>
                <p className={"green"} style={{ fontSize: "24px" }}>
                  {endingData?.todayEnd}
                </p>
              </Col>
              <Col style={{ paddingTop: "18px" }}>
                <p>Tommorow </p>
                <p className="green" style={{ fontSize: "24px" }}>
                  {endingData?.tomorrowEnd}
                </p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                Ground
                <p className="green" style={{ fontSize: "18px" }}>
                  {endingData?.ground}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                Freight{" "}
                <p className="green" style={{ fontSize: "18px" }}>
                  {endingData?.freight}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default EndingCards;
