import React from "react";
import "./App.css";
import ShippingDashboard from "./pages/shipping";
import DetailsView from "./pages/shipping/details";
import TestingDetailsView from "./pages/testing/details";
import QuickListDetailsView from "./pages/quick-list/details";
import ScreeningDetailsView from "./pages/screening/details";
import ShelvingDetailsView from "./pages/shelving/details";
import AllEmployeeListShipping from "./component/shipping/allEmployeeList";
import CleaningDashboard from "./pages/cleaning";
import PhotographyDashboard from "./pages/photography";
import RepairingDashboard from "./pages/repairing";
import ScreeningDashboard from "./pages/screening";
import ShelvingDashboard from "./pages/shelving";
import TestingDashboard from "./pages/testing";
import QuickListDashboard from "./pages/quick-list";
import ReportDashboard from "./pages/report";
import Index from "./pages";
import NotFoundPage from "./pages/404";
import { Route, Routes } from "react-router-dom";
import DetailsCleaningView from "./pages/cleaning/details";
import DetailsPhotographyView from "./pages/photography/details";
import DetailsRepairView from "./pages/repairing/details";
import AllEmployeeListCleaning from "./component/cleaning/AllEmployeeListCleaning";
import AllEmployeeListPhotography from "./component/photography/AllEmployeeListPhotography";
import AllEmployeeListRepairing from "./component/repairing/AllEmployeeListRepairing";
import AllEmployeeListScreening from "./component/screening/AllEmployeeListScreening";
import AllEmployeeListShelving from "./component/shelving/AllEmployeeListShelving";
import AllEmployeeListTesting from "./component/testing/AllEmployeeListTesting";

// experiment
import ExpTempDashboard from "./pages/exp-temp";
import ExpTempDetailsView from "./pages/exp-temp/details";

const publicRoutesName = [
  {
    path: "/",
    component: <Index />,
  },
  //shipping
  {
    path: "/shipping",
    component: <ShippingDashboard />,
  },
  {
    path: "/shipping/details-view",
    component: <DetailsView />,
  },
  // {
  //   path: "/shipping/employee-list",
  //   component: <AllEmployeeListShipping />,
  // },
  //cleaning
  {
    path: "/cleaning",
    component: <CleaningDashboard />,
  },

  {
    path: "/cleaning/details-view",
    component: <DetailsCleaningView />,
  },
  // {
  //   path: "/cleaning/employee-list",
  //   component: <AllEmployeeListCleaning/>
  // },
  //photography
  {
    path: "/photography",
    component: <PhotographyDashboard />,
  },
  {
    path: "/photography/details-view",
    component: <DetailsPhotographyView />,
  },

  // {
  //   path: "/photography/employee-list",
  //   component:<AllEmployeeListPhotography/>
  // },
  // repairing
  {
    path: "/repairing",
    component: <RepairingDashboard />,
  },
  {
    path: "/repairing/details-view",
    component: <DetailsRepairView />,
  },
  // {
  //   path: "/repairing/employee-list",
  //   component:<AllEmployeeListRepairing/>
  // },

  // screening
  {
    path: "/screening",
    component: <ScreeningDashboard />,
  },
  {
    path: "/screening/details-view",
    component: <ScreeningDetailsView />,
  },
  {
    path: "/screening/employee-list",
    component: <AllEmployeeListScreening />,
  },

  // shelving
  {
    path: "/shelving",
    component: <ShelvingDashboard />,
  },
  {
    path: "/shelving/details-view",
    component: <ShelvingDetailsView />,
  },
  {
    path: "/shelving/employee-list",
    component: <AllEmployeeListShelving />,
  },

  // testing
  {
    path: "/testing",
    component: <TestingDashboard />,
  },
  {
    path: "/testing/details-view",
    component: <TestingDetailsView />,
  },
  {
    path: "/testing/employee-list",
    component: <AllEmployeeListTesting />,
  },

  // quick lict
  {
    path: "/quick-list",
    component: <QuickListDashboard />,
  },
  {
    path: "/quick-list/details-view",
    component: <QuickListDetailsView />,
  },
  // {
  //   path: "/quick-list/employee-list",
  //   component: <AllEmployeeListTesting />,
  // },

  // download report
  {
    path: "/report",
    component: <ReportDashboard />,
  },
  // experiment
  {
    path: "/exp-temp-list",
    component: <ExpTempDashboard />,
  },
  {
    path: "/exp-temp/details-view",
    component: <ExpTempDetailsView />,
  },
];
function App() {
  return (
    <div className="App ">
      <Routes>
        {/**************  Start public Route *********************/}
        {publicRoutesName?.map((route, index) => {
          return (
            <Route
              key={index + 1}
              exact
              path={route.path}
              element={<>{route.component}</>}
            />
          );
        })}

        {/**************** No page found *********************/}
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
