import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayPhotography from "../../component/photography/EbayPhotography";
import ToPhotography from "../../component/photography/ToPhotography";
import Photographed from "../../component/photography/Photographed";
import { useGetPhotographyList } from "../../hooks/usePhotography";
import { useNavigate } from "react-router-dom";

const Photography = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allPhotographyList, isLoading: isPhotographyLoading } =
    useGetPhotographyList({
      limit: currentPage,
    });
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <Row lg={12}>
          <Col lg={3}>
            <EbayPhotography
              totalFreightCount={allPhotographyList?.totalFreightCount || 0}
              totalGroundCount={allPhotographyList?.totalGroundCount || 0}
              isPhotographyLoading={isPhotographyLoading}
            />
          </Col>
          <Col lg={6}>
            <ToPhotography
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allPhotographyList={allPhotographyList}
              isPhotographyLoading={isPhotographyLoading}
            />
          </Col>
          <Col lg={3}>
            <Photographed
              dailyAverage={allPhotographyList?.dailyAverage || 0}
              weeklyAverage={allPhotographyList?.weeklyAverage || 0}
              monthlyAverage={allPhotographyList?.monthlyAverage || 0}
              isPhotographyLoading={isPhotographyLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Photography;
// checked
