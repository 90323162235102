import React from "react";
import EndingCards from "./sub-components/EndingCard";
import { EndingCardsSkeleton } from "./sub-components/skeleton";

const EbayRepairing = ({
  totalFreightCount,
  totalGroundCount,
  isRepairingLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">eBay</p>
      {isRepairingLoading ? (
        <EndingCardsSkeleton />
      ) : (
        <EndingCards
          totalFreightCount={totalFreightCount}
          totalGroundCount={totalGroundCount}
        />
      )}
    </div>
  );
};

export default EbayRepairing;
