import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Table from "react-bootstrap/Table";
import ReactApexChart from "react-apexcharts";
import { useGetShippingDetails } from "../../hooks/useShipping";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LeftSkeleton,
  MiddleSkeleton,
  RightSkeleton,
  TableSkeleton,
} from "../../component/screening/sub-components/skeleton";

const DetailsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: allDetails, isLoading: isAllDetailsLoading } =
    useGetShippingDetails({
      uid: state.uid,
    });
  const chartData = {
    series: [600],

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 300, width: 300 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: {
        colors: [
          allDetails?.user?.colorSelector
            ? allDetails?.user?.colorSelector
            : "#7FD320",
        ],
      },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;

                  //   const result = totals.reduce((a, b) => a + b, 0);

                  //   return (result / 1000).toFixed(3);
                  return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <p className="secondHeader">Shipping</p>

        <Row lg={8} className="justify-content-center">
          <Col lg={3}>
            {isAllDetailsLoading ? (
              <>
                <LeftSkeleton />
              </>
            ) : (
              <>
                {/* <Card className="endingStyle">
                  <Container>
                    <Row>
                      <Col style={{ paddingTop: "38px" }}>
                        <p style={{ textAlign: "center" }}>Today </p>

                        <p>
                          Shipped:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            22
                          </span>
                        </p>

                        <p style={{ textAlign: "center" }}>
                          Average Time{" "}
                          <p>
                            Spent:{" "}
                            <span
                              style={{
                                display: "inline-block",
                                color: "yellowgreen",
                              }}
                            >
                              {allDetails?.user?.time
                                ? parseFloat(
                                    allDetails?.user?.time / 60
                                  ).toFixed(2)
                                : 0.0}{" "}
                              Minutes
                            </span>
                          </p>{" "}
                        </p>

                        <p>
                          Total Quantity:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            45
                          </span>
                        </p>

                        <p style={{ textAlign: "center", color: "yellow" }}>
                          Action
                          <p style={{ color: "white" }}>
                            eBay{" "}
                            <span
                              style={{
                                display: "inline-block",
                                color: "yellowgreen",
                              }}
                            >
                              :22
                            </span>
                          </p>
                        </p>

                        <p>
                          POS
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            :0
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Card> */}
                <Card className="endingStyle">
                  <Container>
                    <Row>
                      <Col style={{ paddingTop: "38px" }}>
                        <h5 style={{ textAlign: "center", marginBottom: 5 }}>
                          Today
                        </h5>

                        <p>
                          Shipped:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            {allDetails?.user?.count ?? 0}
                          </span>
                        </p>
                        <h5
                          style={{
                            textAlign: "center",
                            marginBottom: 5,
                            marginTop: 10,
                          }}
                        >
                          Average time
                        </h5>
                        <p>
                          Spent:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            {/* {parseFloat(allDetails?.user?.time / 60).toFixed(2)}{" "} */}
                            {allDetails?.user?.time
                              ? parseFloat(allDetails?.user?.time / 60).toFixed(
                                  2
                                )
                              : 0.0}{" "}
                            Minutes
                          </span>
                        </p>

                        <p style={{ marginTop: 24, marginBottom: 24 }}>
                          Total Quantity:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            {/* {allDetails?.user?.count ?? 0} */}N/A
                          </span>
                        </p>

                        {/* <h5
                          style={{
                            textAlign: "center",
                            color: "yellow",
                            marginBottom: 18,
                          }}
                        >
                          Action
                        </h5>
                        <p style={{ color: "white" }}>
                          eBay:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            {allDetails?.user?.ebay_count ?? 0}
                          </span>
                        </p>

                        <p>
                          POS:{" "}
                          <span
                            style={{
                              display: "inline-block",
                              color: "yellowgreen",
                            }}
                          >
                            {allDetails?.user?.pos_count ?? 0}
                          </span>
                        </p> */}
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </>
            )}
          </Col>
          <Col lg={4}>
            {isAllDetailsLoading ? (
              <>
                <MiddleSkeleton />
              </>
            ) : (
              <>
                <Card className="endingStyle">
                  <h4>
                    {allDetails?.user?.firstName ?? ""}{" "}
                    {allDetails?.user?.lastName ?? ""}
                  </h4>
                  <Container>
                    <Card.Body>
                      <Card.Title>Today</Card.Title>
                      <ReactApexChart
                        height={300}
                        options={chartData.options}
                        series={
                          allDetails?.user?.count
                            ? [allDetails?.user?.count]
                            : [0]
                        }
                        type="donut"
                      />
                    </Card.Body>
                  </Container>
                </Card>
              </>
            )}
          </Col>
          <Col lg={3}>
            {isAllDetailsLoading ? (
              <>
                <RightSkeleton />
              </>
            ) : (
              <>
                <Card className="endingStyle">
                  <Container>
                    <Row>
                      <Col>
                        Daily Average
                        <p
                          className="green"
                          style={{ fontSize: "26px", paddingLeft: "58px" }}
                        >
                          {allDetails?.user?.average || 0}
                        </p>
                        High Score{" "}
                        <p
                          className="green"
                          style={{ fontSize: "26px", paddingLeft: "44px" }}
                        >
                          {allDetails?.user?.highScore || 0}
                        </p>
                        Daily Average Shipped{" "}
                        <p className="green" style={{ fontSize: "21px" }}>
                          {Math.round(allDetails?.user?.dailyAverageShipped) ||
                            0}
                        </p>
                        Weekly Average Shipped{" "}
                        <p style={{ fontSize: "21px", color: "yellow" }}>
                          {Math.round(allDetails?.user?.weeklyAverageShipped) ||
                            0}
                        </p>
                        Monthly Average Shipped{" "}
                        <p className="red" style={{ fontSize: "21px" }}>
                          {Math.round(
                            allDetails?.user?.monthlyAverageShipped
                          ) || 0}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </>
            )}{" "}
          </Col>
        </Row>

        <Row lg={12} className="justify-content-center">
          {isAllDetailsLoading ? (
            <>
              <TableSkeleton />
            </>
          ) : (
            <>
              <Col lg={10} className="new-tble-bx">
                <Table responsive striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Tracking Number</th>
                      <th>Weight</th>
                      <th>Shipping Charge</th>
                      <th>Final Shipping Charge</th>
                      <th>Box Used</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>

                  {allDetails?.assets?.length > 0 ? (
                    <>
                      <tbody>
                        {allDetails?.assets?.map((item, i) => (
                          <tr key={i}>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `https://www.listapp.info/admin/order.php?id=${item?.orderId}`,
                                  "_blank"
                                )
                              }
                            >
                              {item?.order}
                            </td>
                            <td>{item?.trackingNumber}</td>
                            <td>{item?.weight}lbs</td>
                            <td>${item?.shippingCharge}</td>
                            <td>${item?.finalShippingCharge}</td>
                            <td> {item?.box} </td>
                            <td>
                              {" "}
                              {parseFloat(item?.timeSpent / 60).toFixed(2)}{" "}
                              Minutes
                            </td>
                            {/* 
                            <td>{item?.poId}</td> */}
                            {/* <td>{item?.client}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td className="text-center" colspan="7">
                            No data available
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                  {/* </tbody> */}
                </Table>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default DetailsView;
