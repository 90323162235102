import React from "react";
import EndingCards from "./sub-components/EndingCard";
// import LineChart from "./sub-components/LineChart";
// import OrderChart from "./sub-components/OrderChart";
// import PhotographyShelvedItems from "./PhotographyShelvedItems";
import { EndingCardsSkeleton } from "../photography/sub-components/skeleton";
const eBayCleaningValue = {
  today: "178",
  tomorrow: "167",
  ground: "150",
  freight: "8",
};
// const linedata = [
//   ["Year", "Sales", "Expenses", "Profit", "Loss"],
//   ["2004", 1000, 400, 300, 1000],
//   ["2005", 1170, 460, 879, 800],
//   ["2006", 660, 1120, 777, 820],
//   ["2007", 1030, 540, 666, 700],
// ];
// const options = {
//   curveType: "function",
//   legend: "none",
//   backgroundColor: "#2a2f33",
//   hAxis: {
//     textStyle: { color: "#FFF" },
//   },
//   vAxis: {
//     textStyle: { color: "#FFF" },
//   },
// };

// const orderData = [
//   {
//     id: 1,
//     item: "Single Item:",
//     val: "233",
//     val1: "-208933lbs.",
//   },
//   {
//     id: 2,
//     item: "2-5 items:",
//     val: " 233",
//     val1: "-208933lbs.",
//   },
//   {
//     id: 3,
//     item: "Over 6 items:",
//     val: " 233",
//     val1: "-208933lbs.",
//   },
//   {
//     id: 4,
//     item: "Under $100",
//     val: " 10233",
//     val1: "-208933lbs.",
//   },
//   {
//     id: 5,
//     item: "Over $100",
//     val: " 10233",
//     val1: "-208933lbs.",
//   },
// ];
const EbayPhotography = ({
  totalFreightCount,
  totalGroundCount,
  isPhotographyLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">eBay</p>
      {isPhotographyLoading ? (
        <EndingCardsSkeleton />
      ) : (
        <EndingCards
          title={"Ending Soon"}
          value={eBayCleaningValue}
          totalFreightCount={totalFreightCount}
          totalGroundCount={totalGroundCount}
        />
      )}
      {/* <PhotographyShelvedItems />
      <LineChart data={linedata} options={options} />
      <OrderChart orderData={orderData} /> */}
    </div>
  );
};

export default EbayPhotography;
// checked
