import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints/endpoints";
import axiosInstance from "../utilites/axios";
import moment from "moment-timezone";

// export const useGetScreeningList = ({ sortOrder, sortField, date, last }) => {
//   return useInfiniteQuery(
//     "screeningList",
//     async ({ pageParam = 1 }) => {
//       return axiosInstance
//         .get(
//           // `${
//           //   endpoints.screening
//           // }?sortField=firstName&limit=3&sortOrder=asc&last=${last}&date=${moment()
//           //   .tz("America/New_York")
//           //   .format("yyyy-MM-DD")}&previous=${
//           //   pageParam - 1
//           // }&current=${pageParam}`
//           `${
//             endpoints.screening
//           }?sortField=firstName&limit=3&sortOrder=asc&last=${last}&date=2024-04-03&previous=${
//             pageParam - 1
//           }&current=${pageParam}`
//         )
//         .then(({ data }) => {
//           return data;
//         });
//     },
//     {
//       keepPreviousData: true,
//       // enabled: Boolean(currentPage),
//       refetchInterval: 500 * 10,
//       getNextPageParam: (lastPage) => {
//         return lastPage?.users?.length >= 3 ? lastPage?.current + 1 : undefined;
//       },
//     }
//   );
// };
// ${limit * 3}
export const useGetScreeningList = ({ limit }) => {
  return useQuery({
    queryKey: ["screeningList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.screening}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
// ${moment().tz("America/New_York").format("yyyy-MM-DD")}
// 2024-04-09

export const useGetScreeningDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["screeningDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.screening}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
