import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EndingCards = ({ totalFreightCount, totalGroundCount }) => {
  return (
    <Card className="endingStyle ">
      <Container>
        <Row>
          <Col>
            Ground
            <p className="green" style={{ fontSize: "26px" }}>
              {totalGroundCount}
            </p>
          </Col>
          <Col>
            Freight
            <p className="green" style={{ fontSize: "26px" }}>
              {totalFreightCount}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default EndingCards;
// checked
