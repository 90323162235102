import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useGetUserList = () => {
  return useQuery({
    queryKey: ["reportUserList"],
    queryFn: async () => {
      return await axios
        .get("https://metrics-liard.vercel.app/v1/getAllUsers")
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    // refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
