import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const routeData = [
  {
    name: "Shipping",
    path: "/shipping",
  },
  {
    name: "Cleaning",
    path: "/cleaning",
  },
  {
    name: "Repairing",
    path: "/repairing",
  },
  {
    name: "Photography",
    path: "/photography",
  },
  {
    name: "Screening",
    path: "/screening",
  },
  {
    name: "Shelving",
    path: "/shelving",
  },
  {
    name: "Testing",
    path: "/testing",
  },
  {
    name: "Quick List",
    path: "/quick-list",
  },
  {
    name: "Download Report",
    path: "/report",
  },
];
const Index = () => {
  return (
    <>
      <h3 className="header">C2 Metrics</h3>
      <Container className="app-first-page">
        <Row md={12}>
          {routeData.map((item, i) => (
            <Col md={6} lg={3} className="pb-2" key={i}>
              <Card className="endingStyle">
                <Card.Body>
                  <Link to={item.path}>{item.name}</Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Index;
