import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DonutsCard from "./sub-components/donutCards";
import { ToCleaningSkeleton } from "../cleaning/sub-components/skeleton";

const ToShipCleaning = ({
  currentPage,
  setCurrentPage,
  allCleaninggList,
  isCleaningLoading,
}) => {
  const navigate = useNavigate();

  const handleChangePage = (event) => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <>
      <p className="secondHeader">Cleaning</p>
      <Row>
        {isCleaningLoading ? (
          <ToCleaningSkeleton />
        ) : allCleaninggList?.users === undefined ||
          allCleaninggList?.users === null ||
          allCleaninggList?.users?.length === 0 ||
          !allCleaninggList?.users ? (
          <>
            <img
              alt="No data found"
              src={"no-data.gif"}
              style={{
                width: "200px",
                display: "block",
                margin: "0 auto",
                objectFit: "contain",
              }}
            />
          </>
        ) : (
          <>
            {allCleaninggList?.users?.map((user, i) => (
              <Col
                xl={4}
                xxl={4}
                lg={4}
                md={6}
                xs={12}
                sm={12}
                key={i}
                onClick={() => {
                  navigate("/cleaning/details-view", { state: user });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <DonutsCard
                  key={i}
                  left={"Daily Average"}
                  last={"High Score"}
                  user={user}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
      {allCleaninggList?.users?.length === 0 ||
        (allCleaninggList?.users?.length !== allCleaninggList?.totalCount && (
          <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={handleChangePage}
          >
            Load More
          </p>
        ))}
    </>
  );
};

export default ToShipCleaning;
// checked
