import React from "react";
import LineChart from "./sub-components/LineChart";
import OrderChart from "./sub-components/OrderChart";
import EndingCards from "./sub-components/EndingCard";
import DueCards from "./DueCards";

const linedata = [
  ["Year", "Sales", "Expenses", "Profit"],
  ["", 1000, 400, 300],
  ["", 1170, 460, 879],
  ["", 660, 1120, 777],
  ["", 1030, 540, 666],
];
// const linedata = [
//   ["Time", "Sales", "jio"],
//   ["8 A", 1000, 366],
//   ["9 A", 1000, ],
//   ["9 A", 435, 0],
//   ["9 A", 756, 0],
//   ["9 A", 237, 0],
// ];
const options = {
  curveType: "function",
  legend: "none",
  backgroundColor: "#2a2f33",
  hAxis: {
    textStyle: { color: "#FFF" },
  },
  vAxis: {
    textStyle: { color: "#FFF" },
  },
};
const Ebay = ({
  dueData,
  isDueDataLoading,
  endingData,
  isEndingDataLoading,
  orderData,
  isOrderDataLoading,
  pickedData,
  isPickedDataLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">eBay</p>
      <EndingCards
        endingData={endingData}
        isEndingDataLoading={isEndingDataLoading}
      />
      <DueCards
        dueData={dueData}
        isDueDataLoading={isDueDataLoading}
        pickedData={pickedData?.rows}
        isPickedDataLoading={isPickedDataLoading}
      />
      <LineChart data={linedata} options={options} />
      <OrderChart
        orderData={orderData}
        isOrderDataLoading={isOrderDataLoading}
      />
    </div>
  );
};

export default Ebay;
