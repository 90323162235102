import React from "react";

import EndingShipped from "./sub-components/EndingShipped";

import { EndingShippedSkeleton } from "./sub-components/skeleton";
const Tested = ({
  dailyAverage,
  weeklyAverage,
  monthlyAverage,
  isTestingLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">Tested</p>
      {isTestingLoading ? (
        <EndingShippedSkeleton />
      ) : (
        <EndingShipped
          dailyAverage={dailyAverage}
          weeklyAverage={weeklyAverage}
          monthlyAverage={monthlyAverage}
        />
      )}
    </div>
  );
};

export default Tested;
// checked
