import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Table from "react-bootstrap/Table";
import ReactApexChart from "react-apexcharts";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetScreeningDetails } from "../../hooks/useExperimentList";
import {
  LeftSkeleton,
  MiddleSkeleton,
  RightSkeleton,
  TableSkeleton,
} from "../../component/quick-list/sub-components/skeleton";

const QuickListDetailsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  // console.log("Details of user", state);

  const { data: allDetails, isLoading: isAllDetailsLoading } =
    useGetScreeningDetails({
      uid: state.uid,
    });

  // console.log("details", allDetails);

  const chartData = {
    series: [600],

    options: {
      chart: {
        type: "donut",
        foreColor: "#ffffff",
        height: 300,
        width: 300,
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#7FD320"] },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;

                  //   const result = totals.reduce((a, b) => a + b, 0);

                  //   return (result / 1000).toFixed(3);
                  return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <p className="secondHeader">Experiment List</p>

        <Row
          lg={8}
          className="justify-content-center"
          style={{ minWidth: "100%" }}
        >
          <Col lg={3}>
            {isAllDetailsLoading ? (
              <LeftSkeleton />
            ) : (
              <Card className="endingStyle">
                <Container>
                  <Row>
                    <Col style={{ paddingTop: "38px" }}>
                      <h5 style={{ textAlign: "center", marginBottom: 18 }}>
                        Today
                      </h5>

                      <p>
                        Assets:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.ast_count}
                        </span>
                      </p>
                      <p>
                        No Value:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.nv_count}
                        </span>
                      </p>

                      <p style={{ marginTop: 24, marginBottom: 24 }}>
                        Total Quantity:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.qty}
                        </span>
                      </p>

                      <h5
                        style={{
                          textAlign: "center",
                          color: "yellow",
                          marginBottom: 18,
                        }}
                      >
                        Action
                      </h5>
                      <p style={{ color: "white" }}>
                        eBay:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.ebay_count}
                        </span>
                      </p>
                      <p style={{ color: "white" }}>
                        Point of Sale:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.pos_count}
                        </span>
                      </p>
                      {/* <p style={{ color: "white" }}>
                        Scrap:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.scrap_count}
                        </span>
                      </p> */}
                      {/* <p style={{ color: "white" }}>
                        Return to Vendor:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.vendor_count}
                        </span>
                      </p> */}
                      {/* <p style={{ color: "white" }}>
                        C2 Keep:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.C2Keep_count}
                        </span>
                      </p> */}
                      <p style={{ color: "white" }}>
                        Training:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.training_count}
                        </span>
                      </p>
                      {/* <p style={{ color: "white" }}>
                        Audit:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.audit_count}
                        </span>
                      </p> */}
                      {/* <p style={{ color: "white" }}>
                        Send for Sanitization:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.sanitization_count}
                        </span>
                      </p> */}
                      <p style={{ color: "white" }}>
                        GovDeals:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.gov_count}
                        </span>
                      </p>
                      <p>
                        HiBid:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          0
                        </span>
                      </p>
                      <p>
                        Restricted:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.restricted_count}
                        </span>
                      </p>
                      <p>
                        Negotiated:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.negotiation_count}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </Col>
          <Col lg={4}>
            {isAllDetailsLoading ? (
              <MiddleSkeleton />
            ) : (
              <Card className="endingStyle">
                <h4>
                  {allDetails?.user?.firstName +
                    " " +
                    allDetails?.user?.lastName}
                </h4>
                <Container>
                  <Card.Body>
                    <Card.Title>Today</Card.Title>
                    <ReactApexChart
                      options={chartData.options}
                      series={[allDetails?.user?.count]}
                      type="donut"
                      height={300}
                    />
                  </Card.Body>
                </Container>
              </Card>
            )}
          </Col>
          <Col lg={3}>
            {isAllDetailsLoading ? (
              <RightSkeleton />
            ) : (
              <Card className="endingStyle">
                <Container>
                  <Row>
                    <Col>
                      Daily Average
                      <p className="green" style={{ fontSize: "26px" }}>
                        {allDetails?.user?.average}
                      </p>
                      High Score{" "}
                      <p className="green" style={{ fontSize: "26px" }}>
                        {allDetails?.user?.highScore}
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </Col>
        </Row>

        <Row lg={12} className="justify-content-center">
          {isAllDetailsLoading ? (
            <TableSkeleton />
          ) : (
            <Col lg={11} className="new-tble-bx">
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th> Title</th>
                    <th>Pieces</th>
                    <th> Quantity</th>
                    <th>Value</th>
                    <th>Time Spent</th>
                    <th>Action</th>
                    <th>P.O</th>
                    {/* <th>Client</th> */}
                  </tr>
                </thead>
                {allDetails?.assets?.length > 0 ? (
                  <tbody>
                    {allDetails?.assets?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              `https://www.listapp.info/admin/asset_no_to_asset_id.php?assetno=${item?.assetNo}`,
                              "_blank"
                            )
                          }
                        >
                          {item?.assetNo}
                        </td>
                        <td>{item?.title}</td>
                        <td>{item?.pieces}</td>
                        <td>{item?.qty}</td>
                        <td
                          style={{
                            color:
                              item?.value === 1
                                ? "#FD0000"
                                : item?.value === 2
                                ? "#F58021"
                                : item?.value === 3
                                ? "#00A82D"
                                : item?.value === 4
                                ? "rgb(255, 180, 196)"
                                : item?.value === 5
                                ? "#D767FF"
                                : "000",
                          }}
                        >
                          {item?.value === 1
                            ? "LOW ($1 - $200)"
                            : item?.value === 2
                            ? "MED ($201 - $500)"
                            : item?.value === 3
                            ? "HIGH ($501 - $2000)"
                            : item?.value === 4
                            ? "GOLD ($2001 - $5000)"
                            : item?.value === 5
                            ? "DIAMOND ($5001+)"
                            : ""}
                        </td>
                        <td>
                          {parseFloat(item?.timeSpent / 60).toFixed(2)} Minutes
                          {/* {item?.timeSpent || "0.00"} Minutes */}
                        </td>
                        <td>{item?.action}</td>
                        <td>{item?.poId}</td>
                        {/* <td>{item?.client}</td> */}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="text-center" colspan="7">
                        No data available
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default QuickListDetailsView;
// checked
