import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Table from "react-bootstrap/Table";
import Placeholder from "react-bootstrap/Placeholder";

export function LeftSkeleton() {
  return (
    <Col
      xl={4}
      xxl={4}
      lg={4}
      md={6}
      xs={12}
      sm={12}
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Card style={{ width: "100%" }} className="endingStyle">
        <Card.Body>
          <Container>
            <Row>
              <Col style={{ paddingTop: "38px" }}>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ marginBottom: 12 }}
                >
                  <Placeholder xs={6} />
                </Placeholder>

                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>

                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>

                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ marginTop: 12, marginBottom: 12 }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
                {/* <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder> */}
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Col>
  );
}

export function MiddleSkeleton() {
  return (
    <Col
      xl={4}
      xxl={4}
      lg={4}
      md={6}
      xs={12}
      sm={12}
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Card style={{ width: "100%" }} className="endingStyle">
        <Card.Body>
          <Container>
            <Row>
              <Col style={{ paddingTop: "38px" }}>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ marginBottom: 12 }}
                >
                  <Placeholder xs={6} />
                </Placeholder>

                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>

                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ marginTop: 12, marginBottom: 12 }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Subtitle} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </Col>
  );
}

export function RightSkeleton() {
  return (
    <Col
      xl={4}
      xxl={4}
      lg={4}
      md={6}
      xs={12}
      sm={12}
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
        }}
        className="endingStyle"
      >
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={4} style={{ marginBottom: 12 }} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={4} style={{ marginBottom: 12 }} />
          </Placeholder>
        </Card.Body>
      </Card>
    </Col>
  );
}

export function TableSkeleton() {
  return (
    <Col lg={11} className="new-tble-bx">
      <Table responsive striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
            <th>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
            </th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((item) => (
            <tr key={item}>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
              <th>
                <Placeholder
                  as={Card.Title}
                  animation="glow"
                  style={{ textAlign: "left", paddingBottom: "10px" }}
                >
                  <Placeholder xs={6} />
                </Placeholder>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Card style={{ width: "100%" }} className="endingStyle">
                <Card.Body>
                  <Placeholder
                    as={Card.Title}
                    animation="glow"
                    style={{ textAlign: "left", paddingBottom: "10px" }}
                  >
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Subtitle} animation="glow">
                    <Placeholder xs={6} />
                    <Placeholder xs={4} />
                  </Placeholder>
                  <Placeholder as={Card.Subtitle} animation="glow">
                    <Placeholder xs={6} />
                    <Placeholder xs={4} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder
                      xs={4}
                      style={{ textAlign: "left", marginTop: "18px" }}
                    />
                  </Placeholder>

                  <Placeholder
                    xs={8}
                    style={{
                      borderRadius: "50%",
                      width: 130,
                      height: 130,
                      marginTop: 12,
                    }}
                  />
                </Card.Body>
              </Card> */}
    </Col>
  );
}

export function EndingCardsSkeleton() {
  return (
    <Col
      xl={4}
      xxl={4}
      lg={4}
      md={6}
      xs={12}
      sm={12}
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
        }}
        className="endingStyle"
      >
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={4} style={{ marginRight: 12 }} />
            <Placeholder xs={4} style={{ marginLeft: 12 }} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={4} style={{ marginRight: 12 }} />
            <Placeholder xs={4} style={{ marginLeft: 12 }} />
          </Placeholder>
        </Card.Body>
      </Card>
    </Col>
  );
}
export function AverageValuesSkeleton() {
  return (
    <Col
      xl={4}
      xxl={4}
      lg={4}
      md={6}
      xs={12}
      sm={12}
      style={{
        cursor: "pointer",
        width: "100%",
      }}
    >
      <Card
        style={{
          width: "100%",
        }}
        className="endingStyle"
      >
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={6} style={{ marginBottom: 24 }} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={6} style={{ marginBottom: 24 }} />
          </Placeholder>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Subtitle} animation="glow">
            <Placeholder xs={6} style={{ marginBottom: 24 }} />
          </Placeholder>
        </Card.Body>
      </Card>
    </Col>
  );
}

// used
export function ToQuickListSkeleton() {
  return (
    <Row>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <Col
          key={i}
          xl={4}
          xxl={4}
          lg={4}
          md={6}
          xs={12}
          sm={12}
          style={{
            cursor: "pointer",
          }}
        >
          <Card style={{ width: "100%" }} className="endingStyle">
            <Card.Body>
              <Placeholder
                as={Card.Title}
                animation="glow"
                style={{ textAlign: "left", paddingBottom: "10px" }}
              >
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={Card.Subtitle} animation="glow">
                <Placeholder xs={6} />
                <Placeholder xs={4} />
              </Placeholder>
              <Placeholder as={Card.Subtitle} animation="glow">
                <Placeholder xs={6} />
                <Placeholder xs={4} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder
                  xs={4}
                  style={{ textAlign: "left", marginTop: "18px" }}
                />
              </Placeholder>

              <Placeholder
                xs={8}
                style={{
                  borderRadius: "50%",
                  width: 130,
                  height: 130,
                  marginTop: 12,
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
