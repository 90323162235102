import React from "react";

import AverageValues from "./sub-components/AverageValues";
import { AverageValuesSkeleton } from "./sub-components/skeleton";

const QuickListed = ({
  dailyAverage,
  weeklyAverage,
  monthlyAverage,
  isScreeningLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">Quick Listed</p>
      {isScreeningLoading ? (
        <AverageValuesSkeleton />
      ) : (
        <AverageValues
          dailyAverage={dailyAverage}
          weeklyAverage={weeklyAverage}
          monthlyAverage={monthlyAverage}
        />
      )}
    </div>
  );
};

export default QuickListed;
// checked
