import { createSlice } from "@reduxjs/toolkit";
const initialState={
    status:'',
    isLoading:true,
    // getTokenResponse:
}


const ReducerSlice=createSlice({
    name:'Reducer',
    initialState,
    reducers:{
        // getTokenRequest(state, action) {
        //     state.isLoading = true;
        //     state.status = action.type;
        //   },
        //   getTokenSuccess(state, action) {
        //     state.isLoading = false;
        //     state.getTokenResponse = action.payload;
        //     state.status = action.type;
        //   },
        //   getTokenFailure(state, action) {
        //     state.isLoading = false;
        //     state.error = action.error;
        //     state.status = action.type;
        //   },
    }
})

export const {}=ReducerSlice.actions;
export default ReducerSlice.reducer