import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ebay from "../../component/testing/Ebay";
import ToTest from "../../component/testing/ToTest";
import Tested from "../../component/testing/Tested";
import { useGetTestingList } from "../../hooks/useTesting";
import { useNavigate } from "react-router-dom";

const Testing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allTestList, isLoading: isTesingLoading } = useGetTestingList({
    limit: currentPage,
  });
  const navigate = useNavigate();
  return (
    <Container fluid>
      <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
      <Row lg={12}>
        <Col lg={3}>
          <Ebay
            totalFreightCount={allTestList?.totalFreightCount || 0}
            totalGroundCount={allTestList?.totalGroundCount || 0}
            isTestingLoading={isTesingLoading}
          />
        </Col>
        <Col lg={6}>
          <ToTest
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allTestingList={allTestList}
            isTestingLoading={isTesingLoading}
          />
        </Col>
        <Col lg={3}>
          <Tested
            dailyAverage={allTestList?.dailyAverage || 0}
            weeklyAverage={allTestList?.weeklyAverage || 0}
            monthlyAverage={allTestList?.monthlyAverage || 0}
            isTestingLoading={isTesingLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Testing;
// checked
