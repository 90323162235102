import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ebay from "../../component/exp-temp/Ebay";
import ToQuickList from "../../component/exp-temp/ToQuickList";
import QuickListed from "../../component/exp-temp/QuickListed";

// import { useGetScreeningList } from "../../hooks/useExperimentList";
import { useGetScreeningList } from "../../hooks/useExperimentList";
import { useNavigate } from "react-router-dom";

// const allScreenList = {
//   status: true,
//   message: "Users Fetched Successfully",
//   users: [
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Levi",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Darrin",
//       uid: 179,
//       training_count: 0,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       ground_count: 3,
//       ebay_count: 3,
//       count: 3,
//       ast_count: 3,
//       highestCount: 3,
//       average: 0,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Glover",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       count: 1,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Edward",
//       uid: 89,
//       ground_count: 1,
//       ebay_count: 1,
//       training_count: 0,
//       ast_count: 1,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       highestCount: 42,
//       average: 11,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Hernandez",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Joey",
//       uid: 41,
//       training_count: 0,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       restricted_count: 1,
//       pos_count: 1,
//       ground_count: 19,
//       ebay_count: 17,
//       count: 19,
//       ast_count: 19,
//       highestCount: 32,
//       average: 15,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Hamrick",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       firstName: "Jonathan",
//       uid: 120,
//       ground_count: 0,
//       training_count: 0,
//       time: 0,
//       nv_count: 0,
//       gov_count: 2,
//       pos_count: 1,
//       negotiation_count: 1,
//       freight_count: 18,
//       ebay_count: 14,
//       count: 18,
//       ast_count: 18,
//       highestCount: 18,
//       average: 6,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Jernigan",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       count: 1,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Kyle",
//       uid: 165,
//       ground_count: 1,
//       ebay_count: 1,
//       training_count: 0,
//       ast_count: 1,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       highestCount: 5,
//       average: 1,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Carter",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       count: 1,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 1,
//       firstName: "Lloyd",
//       uid: 24,
//       ground_count: 0,
//       ebay_count: 1,
//       training_count: 0,
//       ast_count: 1,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       highestCount: 2,
//       average: 0,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Kirby",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       restricted_count: 0,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Sophia",
//       uid: 178,
//       training_count: 0,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       ground_count: 30,
//       ebay_count: 30,
//       count: 30,
//       ast_count: 30,
//       highestCount: 38,
//       average: 12,
//     },
//     {
//       date: "2024-10-10",
//       auction_count: 0,
//       lastName: "Smith",
//       sanitization_count: 0,
//       C2Keep_count: 0,
//       pos_count: 0,
//       restricted_count: 1,
//       vendor_count: 0,
//       scrap_count: 0,
//       audit_count: 0,
//       gov_count: 0,
//       freight_count: 0,
//       firstName: "Steven",
//       uid: 76,
//       training_count: 0,
//       time: 0,
//       nv_count: 0,
//       negotiation_count: 0,
//       ground_count: 3,
//       ebay_count: 2,
//       count: 3,
//       ast_count: 3,
//       highestCount: 3,
//       average: 1,
//     },
//   ],
//   totalPages: 0,
//   limit: 100,
//   totalGroundCount: 57,
//   totalFreightCount: 19,
//   dailyAverage: 10,
//   weeklyAverage: 61,
//   monthlyAverage: 54,
//   totalCount: 8,
// };

const QuickList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [isScreeningLoading, setIsAllDetailsLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsAllDetailsLoading(false);
  //   }, 2000);
  // }, []);

  const { data: allScreenList, isLoading: isScreeningLoading } =
    useGetScreeningList({
      limit: currentPage,
    });
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <Row lg={12}>
          <Col lg={3}>
            <Ebay
              totalFreightCount={allScreenList?.totalFreightCount || 0}
              totalGroundCount={allScreenList?.totalGroundCount || 0}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
          <Col lg={6}>
            <ToQuickList
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allScreenList={allScreenList}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
          <Col lg={3}>
            <QuickListed
              dailyAverage={allScreenList?.dailyAverage || 0}
              weeklyAverage={allScreenList?.weeklyAverage || 0}
              monthlyAverage={allScreenList?.monthlyAverage || 0}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default QuickList;
// checked
