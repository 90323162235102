import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DonutsCard from "./sub-components/donutCards";
import { ToQuickListSkeleton } from "./sub-components/skeleton";

const ToQuickList = ({
  currentPage,
  setCurrentPage,
  allScreenList,
  isScreeningLoading,
}) => {
  const navigate = useNavigate();

  const handleChangePage = (event) => {
    setCurrentPage((prev) => prev + 1);
  };
  return (
    <>
      <p className="secondHeader">Quick List</p>
      <Row>
        {isScreeningLoading ? (
          <ToQuickListSkeleton />
        ) : allScreenList?.users === undefined ||
          allScreenList?.users === null ||
          allScreenList?.users?.length === 0 ||
          !allScreenList?.users ? (
          <>
            <img
              alt="No data found"
              src={"no-data.gif"}
              style={{
                width: "200px",
                display: "block",
                margin: "0 auto",
                objectFit: "contain",
              }}
            />
          </>
        ) : (
          <>
            {allScreenList?.users?.map((user, i) => (
              <Col
                xl={4}
                xxl={4}
                lg={4}
                md={6}
                xs={12}
                sm={12}
                key={i}
                onClick={() => {
                  navigate("/quick-list/details-view", { state: user });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <DonutsCard
                  key={i}
                  left={"Daily Average"}
                  last={"High Score"}
                  user={user}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
      {/* {allScreenList?.pages[allScreenList?.pages.length - 1]?.limit *
        allScreenList?.pages[allScreenList?.pages.length - 1]?.current <
        allScreenList?.pages[allScreenList?.pages.length - 1]?.totalCount && (
        <p
          className="secondHeader"
          style={{
            cursor: "pointer",
          }}
          onClick={handleChangePage}
        >
          Load More
        </p>
      )} */}
      {allScreenList?.users?.length === 0 ||
        (allScreenList?.users?.length !== allScreenList?.totalCount && (
          <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={handleChangePage}
          >
            Load More
          </p>
        ))}
    </>
  );
};

export default ToQuickList;
// checked
