import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { EndingRepairedSkeleton } from "./sub-components/skeleton";

const Repaired = ({
  dailyAverage,
  weeklyAverage,
  monthlyAverage,
  isRepairingLoading,
}) => {
  return (
    <>
      <div>
        <p className="secondHeader">Repaired</p>
        {isRepairingLoading ? (
          <EndingRepairedSkeleton />
        ) : (
          <Card className="endingCard">
            <Container style={{ paddingRight: "38px" }}>
              <Row>
                <Col style={{ paddingTop: "6px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 20,
                      marginBottom: 0,
                    }}
                  >
                    Daily Average
                  </p>
                  <p className="green" style={{ fontSize: 16 }}>
                    {dailyAverage} Assets
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingTop: "6px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 20,
                      marginBottom: 0,
                    }}
                  >
                    Weekly Average
                  </p>
                  <p className="green" style={{ fontSize: 16 }}>
                    {weeklyAverage} Assets
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingTop: "6px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 20,
                      marginBottom: 0,
                    }}
                  >
                    Monthly Average
                  </p>
                  <p className="green" style={{ fontSize: 16 }}>
                    {monthlyAverage} Assets
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
        )}
      </div>
    </>
  );
};

export default Repaired;
// checked
