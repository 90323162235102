import React from "react";

import EndingCards from "./sub-components/EndingCard";
import { EndingCardsSkeleton } from "./sub-components/skeleton";

const Ebay = ({ totalFreightCount, totalGroundCount, isTestingLoading }) => {
  // const [orderdata, setOrderData] = useState();

  return (
    <div style={{ marginTop: 38 }}>
      {isTestingLoading ? (
        <EndingCardsSkeleton />
      ) : (
        <EndingCards
          totalFreightCount={totalFreightCount}
          totalGroundCount={totalGroundCount}
        />
      )}
    </div>
  );
};

export default Ebay;
// ckecked
