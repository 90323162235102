import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { OrderDataShippedSkeleton } from "./skeleton";

const OrderChart = ({ orderData, isOrderDataLoading }) => {
  if (isOrderDataLoading) {
    return <OrderDataShippedSkeleton />;
  }

  return (
    <Card className="dataStyle header1">
      <Row>
        <Col
          key={() => Math.random()}
          style={{
            paddingLeft: "32px",
            paddingRight: 32,
            paddingTop: 18,
            paddingBottom: 18,
          }}
        >
          <p>Order Data</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Single Item:</p>
            <div>
              <span className="header">{orderData?.single?.val || 0}</span>
              {" - "}
              {orderData?.single?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">2-5 items:</p>
            <div>
              <span className="header">{orderData?.upto_5?.val || 0}</span>
              {" - "}
              {orderData?.upto_5?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Over 6 items:</p>
            <div>
              <span className="header">{orderData?.above_6?.val || 0}</span>
              {" - "}
              {orderData?.above_6?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Under $100:</p>
            <div>
              <span className="header">{orderData?.under_100?.val || 0}</span>
              {" - "}
              {orderData?.under_100?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Over $500:</p>
            <div>
              <span className="header">{orderData?.over_500?.val || 0}</span>
              {" - "}
              {orderData?.over_500?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Over $1000:</p>
            <div>
              <span className="header">{orderData?.over_1000?.val || 0}</span>
              {" - "}
              {orderData?.over_1000?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Over $5000:</p>
            <div>
              <span className="header">{orderData?.over_5000?.val || 0}</span>
              {" - "}
              {orderData?.over_5000?.wt || 0} lbs
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="header">Over $10000:</p>
            <div>
              <span className="header">{orderData?.over_10000?.val || 0}</span>
              {" - "}
              {orderData?.over_10000?.wt || 0} lbs
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default OrderChart;
