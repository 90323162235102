// import { applyMiddleware,  } from 'redux';
// import RootReducer from "./reduxRootReducer/RootReducer";
import { configureStore } from "@reduxjs/toolkit";

import Reducer from "./reducer/Reducer";

// const middleware= [...applyMiddleware]
const store = configureStore({
  reducer: {
    userDash: Reducer,
  },
});
export default store;
