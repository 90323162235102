import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // headers: {
  //   apikey: process.env.API_KEY,
  // },
});

/* The code block is an interceptor for Axios requests. It is used to modify the request configuration
before the request is sent. */
axiosInstance.interceptors.request.use(
  (config) => {
    // change the configuration of the request
    return config;
  },
  (error) => Promise.reject(error)
);

/* The code block is an interceptor for Axios responses. It is used to modify the response data before
it is passed to the `.then()` or `.catch()` methods of the Promise chain. */
axiosInstance.interceptors.response.use(
  function (response) {
    // handle the response as per requirements
    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
