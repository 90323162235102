import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayCleaning from "../../component/cleaning/EbayCleaning";
import Cleeaned from "../../component/cleaning/Cleeaned";
import ToShipCleaning from "../../component/cleaning/ToShipCleaning";
import { useGetCleaningList } from "../../hooks/useCleaning";
import { useNavigate } from "react-router-dom";

const Cleaning = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allCleaningList, isLoading: isCleaningLoading } =
    useGetCleaningList({
      limit: currentPage,
    });
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <Row lg={12}>
          <Col lg={3}>
            <EbayCleaning
              totalFreightCount={allCleaningList?.totalFreightCount || 0}
              totalGroundCount={allCleaningList?.totalGroundCount || 0}
              isCleaningLoading={isCleaningLoading}
            />
          </Col>
          <Col lg={6}>
            <ToShipCleaning
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allCleaninggList={allCleaningList}
              isCleaningLoading={isCleaningLoading}
            />
          </Col>
          <Col lg={3}>
            <Cleeaned
              dailyAverage={allCleaningList?.dailyAverage || 0}
              weeklyAverage={allCleaningList?.weeklyAverage || 0}
              monthlyAverage={allCleaningList?.monthlyAverage || 0}
              isCleaningLoading={isCleaningLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Cleaning;
