import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactApexChart from "react-apexcharts";
import DoubleRingDonutChart from "../../../pages/exp-temp/DoubleRingDonutChart";
const DonutsCard = (props) => {
  // console.log("props", props);
  const chartData = {
    series: props?.user ? [props?.user?.count] : 600,

    options: {
      chart: {
        type: "donut",
        foreColor: "#ffffff",
        height: "100%",
        id: `apexchart-example-${props?.user.uid}`,
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#7FD320"] },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      showForSingleSeries: true,
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;

                  //   const result = totals.reduce((a, b) => a + b, 0);

                  //   return (result / 1000).toFixed(3);
                  return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };
  const fillValue1 = 10;
  const staticVal = 100;

  const staticTiming = 30;

  const [fillValue2, setFillValue2] = useState(35);

  const [chartWidth, setChartWidth] = useState(300);
  const [chartHeight, setChartHeight] = useState(300);

  const staticDataOuter = [
    { label: "Base", value: fillValue1, color: "#ff6347" },
    {
      label: "Static",
      value: staticVal >= fillValue1 ? staticVal - fillValue1 : 0,
      color: "#ccc",
    },
  ];

  const staticDataInner = [
    {
      label: "Base",
      value: fillValue2,
      color: fillValue2 > 0 ? "#00bfff" : "#ccc",
      borderColor: fillValue2 == 0 ? "#00bfff" : "#ccc",
    },
    {
      label: "Static",
      value: fillValue2 > staticTiming ? fillValue2 - staticTiming : 0,
      color: "#ccc",
      borderColor: "#ccc",
    },
  ];
  return (
    <>
      <Card className="endingStyle">
        {/* {props.middle} */}
        {/* <Container> */}
        <Row style={{ minWidth: "100%" }}>
          {props?.user ? (
            <h5 style={{ textAlign: "left", paddingBottom: "10px" }}>
              {(props?.user?.firstName || "John") +
                " " +
                (props?.user?.lastName || "Doe")}
            </h5>
          ) : (
            <h5 style={{ textAlign: "left", paddingBottom: "10px" }}>
              John Doe
            </h5>
          )}
          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.left}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {props?.user?.average ? props?.user?.average : 0}
            </p>
          </Col>

          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.last}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {props?.user?.highestCount ? props?.user?.highestCount : 0}
            </p>
          </Col>
          <Col lg={12}>
            <h6 style={{ paddingTop: "15px" }}>Today</h6>
            <div style={{ marginTop: 20 }}>
              <svg width="300" height="100">
                <g>
                  <rect x="10" y="10" width="20" height="20" fill="#ff6347" />
                  <text x="40" y="25" fontSize="14" fill="#fff">
                    Count: 10(Target:100)
                  </text>
                </g>

                <g>
                  <rect x="10" y="40" width="20" height="20" fill="#00bfff" />
                  <text x="40" y="55" fontSize="14" fill="#fff">
                    Avg. Time: 35 mins(Target: 30 mins)
                  </text>
                </g>
              </svg>
            </div>
            <DoubleRingDonutChart
              staticData={staticDataOuter}
              dynamicData={staticDataInner}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
            />
          </Col>
        </Row>
        {/* </Container> */}
      </Card>
    </>
  );
};

export default DonutsCard;
// checked
