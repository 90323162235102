// This file consists all the endpoints
export const endpoints = {
  screening: "v1/screening" /* screening endpoint */,
  testing: "v1/testing" /* testing endpoint */,
  shipping: "v1/shipping",
  shelving: "v1/shelving",
  cleaning: "v1/cleaning",
  photography: "v1/photography",
  quickList: "v1/quicklist",
  repairing: "v1/repairing",
};
