import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const PhotographyAverageValues = ({
  dailyAverage,
  weeklyAverage,
  monthlyAverage,
}) => {
  return (
    <Card className="endingCard">
      <Container style={{ paddingRight: "38px" }}>
        <Row>
          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 20,
                marginBottom: 0,
              }}
            >
              Daily Average
            </p>
            <p className="green" style={{ fontSize: 16 }}>
              {dailyAverage} Assets
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 20,
                marginBottom: 0,
              }}
            >
              Weekly Average
            </p>
            <p className="green" style={{ fontSize: 16 }}>
              {weeklyAverage} Assets
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 20,
                marginBottom: 0,
              }}
            >
              Monthly Average
            </p>
            <p className="green" style={{ fontSize: 16 }}>
              {monthlyAverage} Assets
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default PhotographyAverageValues;
// checked
