import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ebay from "../../component/screening/Ebay";
import ToScreening from "../../component/screening/ToScreen";
import Screened from "../../component/screening/Screened";

import { useGetScreeningList } from "../../hooks/useScreening";
import { useNavigate } from "react-router-dom";

const Screening = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allScreenList, isLoading: isScreeningLoading } =
    useGetScreeningList({
      limit: currentPage,
    });
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <Row lg={12}>
          <Col lg={3}>
            <Ebay
              totalFreightCount={allScreenList?.totalFreightCount || 0}
              totalGroundCount={allScreenList?.totalGroundCount || 0}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
          <Col lg={6}>
            <ToScreening
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allScreenList={allScreenList}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
          <Col lg={3}>
            <Screened
              dailyAverage={allScreenList?.dailyAverage || 0}
              weeklyAverage={allScreenList?.weeklyAverage || 0}
              monthlyAverage={allScreenList?.monthlyAverage || 0}
              isScreeningLoading={isScreeningLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Screening;
// checked
