import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints/endpoints";
import axiosInstance from "../utilites/axios";
import moment from "moment-timezone";

export const useGetQuickListList = ({ limit }) => {
  return useQuery({
    queryKey: ["screeningList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.quickList}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
// ${moment().tz("America/New_York").format("yyyy-MM-DD")}
// 2024-04-09

export const useGetQuickListDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["screeningDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.quickList}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
