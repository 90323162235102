import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayRepairing from "../../component/repairing/EbayRepairing";
import ToRepairing from "../../component/repairing/ToRepairing";
import Repaired from "../../component/repairing/Repaired";
import { useGetRepairingList } from "../../hooks/useRepairing";
import { useNavigate } from "react-router-dom";

const Repairing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allRepairingList, isLoading: isRepairingLoading } =
    useGetRepairingList({
      limit: currentPage,
    });
  const navigate = useNavigate();
  return (
    <Container fluid>
      <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
      <Row lg={12}>
        <Col lg={3}>
          <EbayRepairing
            totalFreightCount={allRepairingList?.totalFreightCount || 0}
            totalGroundCount={allRepairingList?.totalGroundCount || 0}
            isRepairingLoading={isRepairingLoading}
          />
        </Col>
        <Col lg={6}>
          <ToRepairing
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allRepairingList={allRepairingList}
            isRepairingLoading={isRepairingLoading}
          />
        </Col>
        <Col lg={3}>
          <Repaired
            dailyAverage={allRepairingList?.dailyAverage || 0}
            weeklyAverage={allRepairingList?.weeklyAverage || 0}
            monthlyAverage={allRepairingList?.monthlyAverage || 0}
            isRepairingLoading={isRepairingLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Repairing;
// checked
