import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../utilites/axios";
import moment from "moment";
import { endpoints } from "../endpoints/endpoints";

/**
 * The function `useGetShelvingList` fetches a list of shelving data with a specified limit using axios
 * and moment.js in a React application.
 * @returns The `useGetShelvingList` function is being exported. It returns the result of the
 * `useQuery` hook with a specific configuration for fetching shelving list data. The function makes an
 * asynchronous request to a specific endpoint using axios, processes the response, and handles any
 * errors. It also includes options for refetching the data at a specified interval and handling the
 * success of the query.
 */
export const useGetShelvingList = ({ limit }) => {
  return useQuery({
    queryKey: ["shelvingList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.shelving}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

/**
 * The function `useGetShelvingDetails` fetches shelving details using a specified user ID and current
 * date in the "America/New_York" timezone.
 * @returns The `useGetShelvingDetails` function returns a custom hook that uses `useQuery` from an
 * unknown library to fetch shelving details data from an API endpoint. The function takes a `uid`
 * parameter and constructs a query key based on it. It then makes an asynchronous GET request to the
 * API endpoint using Axios, passing the `uid` and the current date in "America/New_Y
 */
export const useGetShelvingDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["shelvingDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.shelving}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    enabled: Boolean(uid),
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
