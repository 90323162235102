import React from "react";
import { Col, Row } from "react-bootstrap";
import DonutsCard from "./sub-components/donutCards";
import { useNavigate } from "react-router-dom";
import { ToPhotographySkeleton } from "../photography/sub-components/skeleton";

const ToPhotography = ({
  currentPage,
  setCurrentPage,
  allPhotographyList,
  isPhotographyLoading,
}) => {
  const navigate = useNavigate();
  // const [showTable, setShowTable] = useState(false);
  // const [lastPageVal, setLastPageVal] = useState("");

  // const orderData = {
  //   frieght: "8",

  //   ground: "144",
  // };

  const handleChangePage = (event) => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    // <>
    //   {showTable ? (
    //     <>
    //       {" "}
    //       <AllEmployeeListPhotography setShowTable={setShowTable} />
    //     </>
    //   ) : (
    <>
      <p className="secondHeader">Photography</p>

      <Row>
        {isPhotographyLoading ? (
          <ToPhotographySkeleton />
        ) : allPhotographyList?.users === undefined ||
          allPhotographyList?.users === null ||
          allPhotographyList?.users?.length === 0 ||
          !allPhotographyList?.users ? (
          <>
            <img
              alt="No data found"
              src={"no-data.gif"}
              style={{
                width: "200px",
                display: "block",
                margin: "0 auto",
                objectFit: "contain",
              }}
            />
          </>
        ) : (
          <>
            {allPhotographyList?.users?.map((user, i) => (
              <Col
                xl={4}
                xxl={4}
                lg={4}
                md={6}
                xs={12}
                sm={12}
                key={i}
                onClick={() => {
                  navigate("/photography/details-view", { state: user });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <DonutsCard
                  key={i}
                  left={"Daily Average"}
                  last={"High Score"}
                  user={user}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
      {allPhotographyList?.users?.length === 0 ||
        (allPhotographyList?.users?.length !==
          allPhotographyList?.totalCount && (
          <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={handleChangePage}
          >
            Load More
          </p>
        ))}
      {/* old code bellow. should be removed after confirming the new code. */}
      {/* <Row>
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <Col
                xl={4}
                xxl={4}
                lg={4}
                md={6}
                xs={12}
                sm={12}
                key={i}
                onClick={() => {
                  navigate("/photography/details-view");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <DonutsCard
                  left={"Frieght"}
                  last={"Ground"}
                  orderData={orderData}
                  user={[]}
                  setLastPageVal={setLastPageVal}
                />
              </Col>
            ))}
          </Row> */}

      {/* <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // navigate("/shipping/employee-list");
              setShowTable(!showTable);
            }}
          >
            Show more
          </p> */}
    </>
    //   )}
    // </>
  );
};

export default ToPhotography;
// checked
