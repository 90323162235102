import React from "react";

import { AverageValuesSkeleton } from "../screening/sub-components/skeleton";
import AverageValues from "./sub-components/AverageValues";
const Shelved = ({
  dailyAverage,
  weeklyAverage,
  monthlyAverage,
  isShelvingLoading,
}) => {
  return (
    <div>
      <p className="secondHeader">Shelved</p>
      {isShelvingLoading ? (
        <AverageValuesSkeleton />
      ) : (
        <AverageValues
          dailyAverage={dailyAverage}
          weeklyAverage={weeklyAverage}
          monthlyAverage={monthlyAverage}
        />
      )}
    </div>
  );
};

export default Shelved;
// checked
