import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ebay from "../../component/shelving/Ebay";
import ToShelving from "../../component/shelving/ToShelve";
import Shelved from "../../component/shelving/Shelved";
import { useGetShelvingList } from "../../hooks/useShelving";
import { useNavigate } from "react-router-dom";

const Shelving = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { data: allShelvingList, isLoading: isShelvingLoading } =
    useGetShelvingList({
      limit: currentPage,
    });
  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <Row lg={12}>
          <Col lg={3}>
            <Ebay
              totalFreightCount={allShelvingList?.totalFreightCount || 0}
              totalGroundCount={allShelvingList?.totalGroundCount || 0}
              isShelvingLoading={isShelvingLoading}
            />
          </Col>
          <Col lg={6}>
            <ToShelving
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allShelvingList={allShelvingList}
              isShelvingLoading={isShelvingLoading}
            />
          </Col>
          <Col lg={3}>
            <Shelved
              dailyAverage={allShelvingList?.dailyAverage || 0}
              weeklyAverage={allShelvingList?.weeklyAverage || 0}
              monthlyAverage={allShelvingList?.monthlyAverage || 0}
              isShelvingLoading={isShelvingLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Shelving;
// checked
